import {ref, reactive, toRefs, computed, defineComponent,onBeforeMount, onMounted, getCurrentInstance,nextTick} from 'vue';
import ChooseQpListUtil ,{IChooseQpListDataObj} from './chooseQpListUtil';
import FormalOrderDetail from "@/views/project/order/order/formalOrder/formalOrderDetail/FormalOrderDetail.vue";
export default defineComponent ({
    name: 'ChooseQpList',
    props: {
        //以弹出框的形式包含该模块的时候会传入该参数(由jh.core.js的dialog传入)，在此模块中用该参数控制固定列的显示
        engineParams: {
            type: Object
        }
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IChooseQpListDataObj=reactive<IChooseQpListDataObj>({
            pageListRef:null,
            utilInst:{} as any,
            refMap:new Map(),
            pageList: {
                isShowFixCol:false,
                multiple: true,//是否允许多选
                border: false,
                queryParam: {
                    from:props.engineParams?props.engineParams.from:'',
                    customerId:props.engineParams?props.engineParams.customerId:'',
                    cardFrom:'ChooseQpList',
                },
                modelMethod: utils.OrderProviderApi.buildUrl('/chooseQp/pageData')
            },
            otherParams:{

            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new ChooseQpListUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed------------
        //下拉选择
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'flag'==params.comboId){
                    return [{value:0,label:'未提交'},{value:1,label:'已提交'},{value:2,label:'已通知发货'}]
                }
            }
        })

        const formatPageInfo=(options:any)=>{
            return options;
        }
        //查看订单分配和维护等详细信息
        const orderDetail=async (row:any)=>{
            await utils.UtilPub.openDialog({id:'',proxy:proxy,modelComp:FormalOrderDetail,ownerComp:proxy,
                orderId:row.F_ORDER_ID,childOrderCode:row.F_SPLIT_ORDER_CODE,
                cardFrom:'ChooseQpList',overflow:'hidden',title: '订单详情',contentHeight:'',showFooterBtn:false,fullscreen:true})
        }
        return{
            ...toRefs(dataObj),comboSelect,formatPageInfo,orderDetail
        }
    }
});