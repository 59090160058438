import BaseBean from "@/utils/BaseBean";

import _ from "lodash";
export interface IBgzlContentCardDataObj {
    utilInst:BgzlContentCardUtil
    formRef:any
    disabled:boolean
    refMap:Map<string,any>
    compParams: any
    form:any
    rules:TFormRule
    otherParams:any
}
export default class BgzlContentCardUtil extends BaseBean{
    public dataObj:IBgzlContentCardDataObj;

    constructor(proxy:any,dataObj:IBgzlContentCardDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //很可惜，在这里拿不到mainId和type
    }

    //检查所选行是否已经存在
    public checkExist(row:any):boolean{
        let tbData=this.dataObj.refMap.get('bgzlContentDetail').getDetailData();
        return tbData.findIndex((item:any)=>item.F_DETAIL_ID==row.F_ID)==-1;
    }
    //处理弹出框确认
    public doSureChoose(selectRows:any,type:number):any{
        //type:0-合同 1-箱单 2-发票
        selectRows.forEach((row:any)=>{
            if(this.checkExist(row)){//不存在
                row.F_DETAIL_ID=row.F_ID;
                delete row.F_ID;//删除F_ID，否则addRow的时候辉往更新数组里面添加
                delete row.rowIndex;
                let tempRow = _.cloneDeep(row);
                tempRow.F_MAX_NUM=row.F_NUM-row.F_BG_NUM;//本次可以入库的最大数量,用作控件限制输入用,可惜只能新增的时候用，编辑的时候就不能限制了
                tempRow.F_NUM=row.F_NUM-row.F_BG_NUM;//默认填入本次可以入库的最大数量
                this.dataObj.refMap.get('bgzlContentDetail').addRow(tempRow);
            }
        })
    }
}