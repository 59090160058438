import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import BgzlContentCardUtil,{IBgzlContentCardDataObj} from './bgzlContentCardUtil';
import ChooseQpList from "@/views/project/order/fy/bgzl/chooseQp/ChooseQpList.vue";
import ChooseHtList from "@/views/project/order/fy/bgzl/chhoseHt/ChooseHtList.vue";
export default defineComponent({
    name:'BgzlContentCard',
    title:'报关资料内容',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IBgzlContentCardDataObj=reactive<IBgzlContentCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    bgzlContentDetail:{},
                },
                details:['bgzlContentDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/bgzlContent')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {
                bghthm: [utils.UtilPub.commonValidRule('合同号码不能为空')],//合同信息单的时候会验证该字段
                bghtId: [utils.UtilPub.commonValidRule('合同号码必须选择')],//发票和箱单模块会验证该字段
                fphm: [utils.UtilPub.commonValidRule('发票号码不能为空')],//发票模块会验证该字段
                fpId: [utils.UtilPub.commonValidRule('发票号码必须选择')],//箱单模块会验证该字段
            },
            otherParams:{
                bghtData:[],//合同下拉数据集
                fpData:[],//发票下拉数据集
                fpDataBak:[],//发票下拉数据集备份，在箱单信息模块，合同切换的时候不用再去请求后台了
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BgzlContentCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //下拉select数据集获取（数据集构造和cascader类似）
        const comboSelect=computed(()=>{
            return (params:any) => {
                if(params && 'bghtId'==params.comboId){
                    return dataObj.otherParams.bghtData;
                }
                if(params && 'fpId'==params.comboId){
                    return dataObj.otherParams.fpData;
                }
            }
        })
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'订单信息',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        canAdd: false,
                        toolBar: [{
                            id: "chooseOrder",
                            title:'选择启票',
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/bgzlContent/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //---------------------------selectOnchange--------------------------
        const selectOnChange=async (newValue:any,selectId:string)=> {
            if('bghtId'==selectId){//能够让合同下拉改变的，只有发票和箱单模块
                if(dataObj.form.type==1){//现在是在箱单信息模块，合同切换的时候，要把对应的发票过滤出来
                    dataObj.form.fpId='';
                    if(newValue)dataObj.otherParams.fpData=dataObj.otherParams.fpDataBak.filter((item:any)=>item.item.bghtId==newValue)
                }
                dataObj.refMap.get('bgzlContentDetail').clearDetailData();
            }
            if('fpId'==selectId){//能够操作发票下拉模块的只有箱单模块，发票切换，需要把明细数据清除
                dataObj.refMap.get('bgzlContentDetail').clearDetailData();
                if(newValue)await dataObj.refMap.get('bgzlContentDetail').reloadGrid({bghtId:dataObj.form.bghtId,fpId:newValue,queryType:'fpxxd'});
            }
        }
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            //合同、发票弹出框的时候，改变标题
            let title='选择启票';
            let modelComp=ChooseQpList;
            let type=dataObj.form.type;
            switch (type) {
                case 2:
                    if(!dataObj.form.bghtId){
                        proxy.$message('请先选择合同号码');
                        return ;
                    }
                    title='选择合同';
                    modelComp=ChooseHtList;
                    break;
            }
            await utils.UtilPub.openDialog({
                proxy:proxy,modelComp:modelComp,ownerComp:proxy,
                type:dataObj.form.type,customerId:dataObj.form.customerId,
                bghtId:dataObj.form.bghtId,
                from:'BgzlContentCard',overflow:'hidden',title: title,showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            dataObj.utilInst.doSureChoose(selectRows,engineRef.engineParams.type)
            return true;
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            proxy.dialogInst.options.title=engineInst.engineParams.title;//设置标题
            dataObj.form.type=engineInst.engineParams.type;//[0-合同信息单 1-箱单信息单 2-发票信息单]
            dataObj.form.mainId=engineInst.engineParams.mainId;//报关资料大id
            dataObj.form.customerId=engineInst.engineParams.customerId;//报关资料选择的客户
            let title='选择启票';//各个信息单模块的明细表格标题
            let comboData;
            //当不是合同信息模块的时候，打开的是发票或者箱单信息模块，需要请求后台，为合同或者发票构造下拉数据集
            if(dataObj.form.type!=0)comboData=await utils.OrderProviderApi.getHtByMainId({params:{mainId:dataObj.form.mainId,type:dataObj.form.type}});
            switch (dataObj.form.type) {
                case 1://当前在箱单模块，它选择的明细数据应该来自发票信息单，所以明细表格的选择按钮叫做选择发票，该模块表单内容要用到合同和发票下拉数据
                    dataObj.otherParams.bghtData=comboData.bghtData;
                    dataObj.otherParams.fpData=comboData.fpData;
                    dataObj.otherParams.fpDataBak=comboData.fpData;
                    if('/load'==addOrLoad)dataObj.otherParams.fpData=dataObj.otherParams.fpDataBak.filter((item:any)=>item.item.bghtId==res.data.bghtId)
                    break;
                case 2://当前在发票模块，它选择的明细数据应该来自合同信息单，所以明细表格的选择按钮叫做选择合同，该模块表单内容要用到合同下拉数据
                    title='选择合同';
                    dataObj.otherParams.bghtData=comboData.bghtData;
                    break;
            }
            if(dataObj.form.type==1){//如果是发票信息单模块，则不需要工具栏，直接根据合同和发票加载明细表格数据,且不需要固定列中的删除，它需要和具体的发票明细保持一致
                dataObj.refMap.get('bgzlContentDetail').editParams.isShowFixCol=false;
                dataObj.refMap.get('bgzlContentDetail').editParams.toolBar=[];
            }else{
                dataObj.refMap.get('bgzlContentDetail').editParams.toolBar[0].title=title;//设置明细表格工具栏标题
            }
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            /**
             * 从报关资料表单那里点击具体信息单或者新增具体信息单就会进入bgzlContent（报关资料信息单内容页），于是就会把报关资料那里的
             * mainId（报关资料id），具体的信息单id（id）传过来；很可惜，在engineUtil明细表格请求后台的时候，会用id替换mainId，
             * 于是传到后台的时候，查询明细的时候，如果还按照mainId来取，就有问题。于是我就把信息单的id单独命名bgzlContentId，后台查询
             * 明细数据的时候，拿这个参数就不用有问题了，你也许会担心，mainId已改变，后台存储是不是有问题，不用担心，在beforeOpen那里已经把
             * 正确的mainId存下来了。
             * */
            return {cardFrom:engineInst.engineParams.cardFrom,type:engineInst.engineParams.type,mainId:engineInst.engineParams.mainId,bgzlContentId:engineInst.engineParams.id};
        }
        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            return true;
        }
        //保存之后
        const afterSaveHandler=(res:any)=>{
            proxy.engine.engineParams.ownerComp.engine.engineUtil.doAddOrLoad(dataObj.form.mainId,'card');
            return true;
        }
        //明细表格加载完毕事件
        const gridLoaded=(detailType:string,res:any)=>{
            res.rows.forEach((row:any)=> {
                if(!row.F_ID)dataObj.refMap.get(detailType).setInsert(row);
            });
        }
        //计算某行的总金额
        const rowAmount=computed(()=>{
            return (row:any) => {
                if(row.F_NUM && row.F_PRICE){
                    let amount=row.F_NUM*row.F_PRICE;
                    row.F_AMOUNT=amount;
                    return amount;
                }
                row.F_AMOUNT=0;
                return 0;
            };
        })
        //删除之后
        const afterDeleteHandler=(id:string)=>{
            proxy.engine.engineParams.ownerComp.engine.engineUtil.doAddOrLoad(dataObj.form.mainId,'card');
        }
        return{
            ...toRefs(dataObj),proxy,comboSelect,cardDetailParam,selectOnChange,chooseOrder,sureHandler,beforeOpen,setParam,beganEdit,beforeEndEdit,beforeSaveHandler,
            afterSaveHandler,gridLoaded,rowAmount,afterDeleteHandler
        }
    }
});